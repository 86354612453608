import { graphql } from "gatsby";
import React from "react";
import ArticleFeed from "../components/articlefeed";
import ArticlePreviewOverlaidText from "../components/articlepreviewoverlaidtext";

import Layout from "../components/layout";
import RelatedContent from "../components/relatedcontent";
import SEO from "../components/seo";

const CompanyNewsPage = ({ data }: any) => {
  data.allPrismicArticle.edges.sort(
    (a: any, b: any) =>
      new Date(b.node.data.date).valueOf() -
      new Date(a.node.data.date).valueOf()
  );
  return (
    <Layout>
      <SEO title="Company News" description="" lang="en" meta={[]} />
      {/* Top Featured */}
      <div className="container large-banner">
        <div className="row">
          <div className="col-lg">
            <ArticlePreviewOverlaidText
              data={data.allPrismicArticle.edges[0].node}
            />
          </div>
        </div>
      </div>

      {/* Article Feed */}
      <div className="container">
        <h2>More Flapmax News</h2>
        <div className="row">
          <ArticleFeed data={data} startIndex={1} />
          <div className="col-lg-4">
            <RelatedContent />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicArticle(filter: { tags: { in: "Company News" } }) {
      edges {
        node {
          tags
          uid
          data {
            date
            header_image {
              url
            }
            header_image_caption {
              text
            }
            title {
              text
            }
            author {
              text
            }
          }
        }
      }
    }
  }
`;

export default CompanyNewsPage;
